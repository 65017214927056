import { ButtonSize } from 'emex-ui-kit/dist/Button';
import { NotFound } from 'emex-ui-kit/dist/NotFound';
import { ServerError } from 'emex-ui-kit/dist/ServerError';
import useTranslation from 'next-translate/useTranslation';

import { Button } from '@components/NextRouteHOC';
import GlobalClientError from '@components/GlobalClientError';
import LogoWrapper from '@components/LogoWrapper';

import pageUrls from '@constants/pageUrls';

const Error = ({ statusCode }: { statusCode?: number }): JSX.Element => {
  const { t } = useTranslation();

  if (statusCode === undefined) {
    return <GlobalClientError />;
  }

  if (statusCode === 404) {
    return (
      <NotFound
        title={t('errorPages:pageDoesNotExist')}
        button={
          <Button size={ButtonSize.BIG} to={pageUrls.home()}>
            {t('errorPages:goShopping')}
          </Button>
        }
        LogoWrapper={LogoWrapper}
      />
    );
  }

  return (
    <ServerError
      title={t('errorPages:serverError')}
      subtitle={t('errorPages:serverErrorDescription')}
      code={statusCode}
      LogoWrapper={LogoWrapper}
    />
  );
};

export default Error;
