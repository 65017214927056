import { NextPageContext } from 'next';

import ErrorContent from '@pagesContent/error';
import Head from '@components/Head';

import getTitleDomainPart from '@shared/getTitleDomainPart';
import useTranslation from 'next-translate/useTranslation';

type TErrorProps = {
  host: string;
  /** Статус-код ошибки */
  statusCode?: number;
};

const Error = ({ statusCode, host }: TErrorProps): JSX.Element => {
  const { t } = useTranslation();

  const errorText = statusCode ?? t('common:somethingWentWrong');

  return (
    <>
      <Head>
        <title>{`${errorText} — ${getTitleDomainPart(host)}`}</title>
      </Head>
      <ErrorContent statusCode={statusCode} />
    </>
  );
};

Error.getInitialProps = async ({ res, err }: NextPageContext) => {
  if (res) {
    return { statusCode: res.statusCode };
  }

  if (err) {
    return { statusCode: err.statusCode };
  }

  return { statusCode: 404 };
};

export default Error;
