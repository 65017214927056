const EMEX_TEXT = 'EmexAuto';

/**
 * Получение преобразованного адреса домена (используется для формирования тайтла)
 * @param host - домен
 */
export default function getTitleDomainPart(host: string): string {
  if (!host) {
    return EMEX_TEXT;
  }

  const parts = host.split('.');

  return parts.length > 1 ? `${EMEX_TEXT}.${parts.slice(-1)[0]}` : EMEX_TEXT;
}
